<template>
  <div>
    <titleBar
      v-show="!isInboxHome"
      :title="titleBarTitle"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="section" :class="{ isInboxHome: isInboxHome, notHome: !isInboxHome }">
      <div class="container w800">
        <div class="columns">
          <div class="column is-narrow zone list" style="display: none">
            <jsonDebug :data="interlocutors" label="interlocutors" />

            <inboxList class="inboxList" :interlocutors="interlocutors" style="" />
          </div>
          <div class="column zone convo">
            <inboxThread
              :bot="bot"
              :convo="convo"
              :convos="convos"
              :newRoute="newRoute"
              @reloadMsg="reloadMsg"
              v-if="bot"
              :shareMode="true"
            ></inboxThread>

            <empty
              v-if="!$route.params.bot && !bot"
              title="Select a chat or start a new conversation"
              btTo="/explore"
              btLabel="Explore new AIs"
            />

            <jsonDebug :data="bot" label="bot" />
            <jsonDebug :data="convo" label="active convo" />
            <!-- -->
            <jsonDebug :data="convos" label="other convos" />
            <jsonDebug :data="tasks" label="tasks" />
          </div>
        </div>
      </div>

      <!--
    <v-row no-gutters>
      <v-col v-if="!isMobile || isInboxHome" cols="3">
        <v-list>
          <v-list-item
            v-for="(interlocutor, index) in interlocutors"
            :key="index"
            :to="{ name: 'inbox-thread', params: { conversationId: interlocutor.conversationId } }"
          >
            <v-list-item-avatar>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ interlocutor.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ interlocutor.lastMessage }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>

      <v-col v-if="!isInboxHome || (isMobile && interlocutors.length === 0)" cols="12" sm="9">
        <router-view></router-view>
      </v-col>
    </v-row> -->
    </div>
  </div>
</template>

<script>
import JsonDebug from "@/components/e/jsonDebug.vue";
import inboxList from "@/components/inbox/InboxList.vue";
import inboxThread from "@/components/inbox/inboxThread.vue";

export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  name: "InboxLayout",
  components: {
    inboxList,
    inboxThread,
    //  JsonDebugt,
  },
  data() {
    return {
      // featuredTags,
      interlocutors: [],
      convos: [],
      convo: null,
      tasks: [],
      bot: null,
      //keyFeatures,
      // projs: [],
      loading: true,
      loadingTasks: false,
      // loadingOps: true,
    };
  },
  mounted() {
    //window.API.getFeaturedBots().then((data) => {
    /*
    window.API.me.getUserInterlocutors().then((data) => {
      this.interlocutors = data;
      this.loading = true;
      this.loadThread(this.$route.params.bot, this.$route.params.convo);
      // console.log("PROJJ!", proj, proj.id);
    });*/
    this.loadThread(this.$route.params.bot, this.$route.params.key);
  },
  watch: {
    $route(to, from) {
      this.loadThread(to.params.bot, to.params.key);
    },
  },
  methods: {
    loadThread(bot, key) {
      // make an API call to fetch thread data
      // and update the thread data in the component's data
      console.log("LOADING THREAD", bot, key);
      if (!bot) return; //this is home inbox.

      /**/
      if (this.bot && this.bot.handle != bot) {
        //prevent bad state to show on mobile
        this.bot = null;
      }

      window.API.botProfile(bot).then((b) => {
        this.bot = b;
        //  this.loadingBots = false;
        // console.log("PROJJ!", proj, proj.id);
      });

      window.API.getPublicConvo(key).then((b) => {
        this.convo = b;
        //  this.loadingBots = false;
        // console.log("PROJJ!", proj, proj.id);
      });

      //convos
      /*
      const userId = this.$store.main.getters.profile.handle;
      window.API.getUserBotConvos(bot, userId).then((b) => {
        this.convos = b;
        //  this.loadingBots = false;
        // console.log("PROJJ!", proj, proj.id);
      });
      //tasks
      window.API.getBotTasks(bot).then((tasks) => {
        this.tasks = tasks;
        this.loadingTasks = false;
        // console.log("PROJJ!", proj, proj.id);
      });
*/
    },
    reloadMsg() {
      //when user sends a message. Without the STORE.
      // alert(3);
      window.API.getUserBotConvo(this.$route.params.bot, this.$route.params.convo).then((b) => {
        this.convo = b;
        //   alert(4);
        // show toast buefy
        this.$buefy.toast.open({
          message: "Message receive+ reloaded!",
          type: "is-success",
          position: "is-bottom-right",
          duration: 2000,
        });
      });
    },
  },
  computed: {
    isInboxHome() {
      return this.$route.name === "inbox";
    },
    isNewMessage() {
      return this.$route.name === "inboxNewConvo" || this.$route.name === "inboxNewConvoTask";
    },
    interlocutorsShown() {
      //filter and add some props like "active., typing, etc..."
      return this.interlocutors;
      // Return a list of interlocutors here based on some state or props
    },

    newRoute() {
      return this.isNewMessage;
    },
    isMobile() {
      return true;
      // Return a boolean indicating whether the user is on a mobile device
    },
    titleBarTitle() {
      if (this.isInboxHome) return "Chats";
      if (this.isNewMessage) return this.$route.params.bot + " ✨ ";
      if (this.bot) return this.bot.handle;
      var rb = this.$route.params.bot;
      if (rb) return rb;
      return ""; //prevent flickering, but let user go back while slow load.
    },
    title() {
      return this.titleBarTitle + " / DM";
    },
  },
};
</script>

<style scoped>
.zone {
  /*
  border-radius: 50px;
  background-color: white;

  border: 1px #ccc solid;*/
  padding: 0;
}
/* on destop bigger padding */
@media only screen and (min-width: 1022px) {
  .zone {
    padding: 20px;
  }
}

.zone.list {
  border-radius: 50px;
  background-color: white;
}

/* mobiel selective columns depending on class isInboxHome using media query*/

.inboxList {
  max-width: 300px;
}
@media only screen and (max-width: 1022px) {
  .isInboxHome .zone.convo {
    display: none;
  }
  .notHome .zone.list {
    display: none;
  }
  .inboxList {
    max-width: 999px;
  }
}
</style>
